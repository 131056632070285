import React, {useState} from 'react';
import {AccordionDetails, ExpansionPanel, ExpansionPanelSummary, Grid} from "@material-ui/core";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import ArrowIcon from "../../../../images/icons/ArrowIcon";
import {graphql, Link, useStaticQuery} from "gatsby";

const useStyles = makeStyles(() => ({
  bgLightGray: {
    border: "1px solid rgba(0,0,0,0.05)",
    borderRadius: "5px",
    backgroundColor: 'none',
    padding: '10px',
    "& .MuiExpansionPanel-root:before": {
      backgroundColor: 'none'
    }
  },


}));

const PopularArticles = () => {
  const {allMdx} = useStaticQuery(
    graphql`
        query popular {
            allMdx(
                filter: {frontmatter: {Popular: {eq: true}}}
                limit: 5
                sort: {order: ASC, fields: headings___value}
            ) {
                edges {
                    node {
                        frontmatter {
                            category
                            title
                            tab
                        }
                        fields {
                            slug
                        }
                    }
                }
            }
        }
    `
  );
  const [tabIndex] = useState(0);
  const [expandedIndex, setExpandedIndex] = useState(null);

  const classes = useStyles();

  return (
    <>
      <div className="py-15 text-center">
        <h2 className="text-36">Popular Articles</h2>
        <p>Selected featured articles to get you started fast in VPC+</p>

        <Grid container spacing={3} className="justify-center">
          <Grid item lg={8} xl={12} xs={12} md={12} sm={12}>
            {tabIndex === 0 && (
              <div className="py-5">
                {allMdx.edges.map((item, ind) => (
                  <ExpansionPanel
                    key={ind}
                    className={clsx({
                      "border-radius-4 mb-6": true,
                      listHover: true,
                      "elevation-z3": expandedIndex === ind,
                      "box-shadow-none": expandedIndex !== ind,
                    })}
                    expanded={expandedIndex === ind}
                  >
                    <Link to={item.node.fields.slug} className={classes.listHover}>
                      <ExpansionPanelSummary
                        expandIcon={<ArrowIcon style={{fontSize: '13px'}}/>}
                        className={clsx({
                          "text-primary font-medium": true,
                          [classes.bgLightGray]: true,

                          "bg-primary text-white": expandedIndex === ind,
                        })}
                      >
                        {item.node.frontmatter.title}
                      </ExpansionPanelSummary>
                    </Link>
                  </ExpansionPanel>
                ))}
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
}
export default PopularArticles;

