import React from "react";
import {Grid, Card} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import AwsCloud from '../../../../images/aws-logo.png'
import GcpCloud from '../../../../images/google-cloud-logo.png'
import IbmCloud from '../../../../images/ibm-logo.png'
import {Link} from "gatsby";

const useStyles = makeStyles(() => ({
  card: {
    boxShadow: "0 5px 15px rgb(0 0 0 / 8%)",
    borderRadius: "10px",
    "&:hover": {
      cursor: "pointer",
      boxShadow: "0 10px 35px rgb(0 0 0 / 10%) !important",
    },
  },
  cloudImg: {
    width: '25%',
  }
}));

const CloudTabsView = () => {
  const classes = useStyles();
  return (
    <div className="py-10 mb-10">
      <div className="text-center pb-10 ">
        <h2 className="text-36">Browse By Your Cloud</h2>
        <p>Choose a public cloud that you need help with</p>
      </div>
      <div className="text-center">
        <Grid container spacing={5}>

          <Grid item lg={4} md={4} sm={12} xs={12} key={0}>
            <Link to='/aws'>
              <Card className={clsx("relative overflow-hidden card", classes.card)}>
                <div className="p-8">
                  <img src={AwsCloud} className={classes.cloudImg}/>
                  <h3>Amazon Web Services</h3>
                </div>
              </Card>
            </Link>
          </Grid>


          <Grid item lg={4} md={4} sm={12} xs={12} key={2}>
            <Link to='/ibm'>
              <Card className={clsx("relative overflow-hidden card", classes.card)}>
                <div className="p-8">
                  <img src={IbmCloud} className={classes.cloudImg}/>
                  <h3>IBM Cloud</h3>

                </div>
              </Card>
            </Link>
          </Grid>

          <Grid item lg={4} md={4} sm={12} xs={12} key={1}>
            <Link style={{cursor: 'not-allowed'}}>
              <Card className={clsx("relative overflow-hidden card", classes.card)} style={{cursor: 'not-allowed'}}>
                <div className="p-8">
                  <img src={GcpCloud} className={classes.cloudImg} style={{filter: 'grayscale(100%)'}}/>
                  <h3 style={{color: '#7f7f7f'}}>Google Cloud Platform</h3>

                </div>
              </Card>
            </Link>
          </Grid>


        </Grid>
      </div>
    </div>
  );
};

export default CloudTabsView;
