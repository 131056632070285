import React from "react";
import {Grid, Card} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import {Link} from "gatsby";

const useStyles = makeStyles(({palette, ...theme}) => ({
  card: {
    boxShadow: "0 5px 15px rgb(0 0 0 / 8%)",
    borderRadius: "10px",
    "&:hover": {
      cursor: "pointer",
      boxShadow: "0 10px 35px rgb(0 0 0 / 10%) !important",
    },
  },
}));

const VideoTutorialView = () => {
  const classes = useStyles();

  return (

      <div className="py-10 mb-10">
        <div className="text-center mb-10">
          <h2 className="text-36">Video Tutorials</h2>
          <p>Watch videos to get started</p>
        </div>
        <div className="text-center max-auto">
          <Grid container spacing={4} alignItems="center" justify="center">
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Link to='/videos/explainer'>
              <Card className={clsx("relative overflow-hidden card", classes.card)}>
                <div className="p-8">
                  <PlayCircleOutlineIcon className="text-72 text-primary"/>
                  <h3>Explainers</h3>
                </div>
              </Card>
              </Link>
            </Grid>

            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Link to='/videos/product'>
              <Card className={clsx("relative overflow-hidden card", classes.card)}>
                <div className="p-8">
                  <PlayCircleOutlineIcon className="text-72 text-primary"/>
                  <h3>Product Demo</h3>
                </div>
              </Card>
              </Link>
            </Grid>

            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Link to='/videos/webinar'>
              <Card className={clsx("relative overflow-hidden card", classes.card)}>
                <div className="p-8">
                  <PlayCircleOutlineIcon className="text-72 text-primary"/>
                  <h3>Webinars</h3>
                </div>
              </Card>
              </Link>
            </Grid>

          </Grid>
        </div>
      </div>

  );
};

export default VideoTutorialView;
