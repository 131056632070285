import React, {useState} from 'react';
import {AccordionDetails, ExpansionPanel, ExpansionPanelSummary, Grid} from "@material-ui/core";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import ArrowIcon from "../../../../images/icons/ArrowIcon";

const useStyles = makeStyles(() => ({
  bgLightGray: {
    border: "1px solid rgba(0,0,0,0.05)",
    borderRadius: "5px",
    backgroundColor: 'none',
    padding: '10px',
    "& .MuiExpansionPanel-root:before": {
      backgroundColor: 'none'
    }
  },
}));

const FrequentlyAskedQuestionsView = () => {
  const [tabIndex] = useState(0);
  const [expandedIndex, setExpandedIndex] = useState(null);

  const classes = useStyles();

  const categoryList = [
    {
      title: "Do you offer custom migrations?",
      description: "Yes, we do offer custom migration options based on your needs. " +
        "Please contact our sales team at sales@wanclouds.net for a free consultation."
    },
    {
      title: "How fast is VPC+ MaaS?",
      description: "Every migration is different. However, given our holistic approach and leveraging our pre-built " +
        "integrations, our customers migrate 10x faster, on average, than manual migrations from initial engagement to " +
        "completion.  You can read our bench-marking report here."
    },
    {
      title: "How do I get started?",
      description: "We’d love to learn more about your migration challenges and look " +
        "forward to working with you to overcome them. To get started, contact us at sales@wanclouds.net for a free consultation."
    },
    {
      title: "I am a Cloud or Service Provider, can I leverage VPC+ MaaS capabilities?",
      description: "Sure, if you are looking for technology and capabilities to help your enterprise customers migrate " +
        "from traditional or vendor-locked infrastructure or databases to a more flexible cloud native infrastructure, " +
        "we would be happy to help and partner with you.Please contact our partnerships team here."
    },
    {
      title: "Do you offer services post-migration ?",
      description: "Yes, you can manage your VPC environment in a self-managed way and in addition to that, we have " +
        "infrastructure monitoring as a service as well where you just pay for what you manage on a monthly or yearly " +
        "basis. To learn more, visit our Ongoing Services page for details."
    },
  ];
  return (
    <div className="py-15 text-center mb-10">
      <h2 className="text-36">Frequently asked questions</h2>
      <p>Find quicke answers to frequent pre-sale questions asked by customers</p>

      <Grid container spacing={3} className="justify-center">
        <Grid item lg={8} xl={12} xs={12} md={12} sm={12}>
          {tabIndex === 0 && (
            <div>
              {categoryList.map((item, ind) => (
                <ExpansionPanel
                  key={ind}
                  className={clsx({
                    "border-radius-4 mb-6": true,
                    "elevation-z3": expandedIndex === ind,
                    "box-shadow-none": expandedIndex !== ind,
                  })}
                  onChange={(e, expanded) =>
                    expanded ? setExpandedIndex(ind) : setExpandedIndex(null)
                  }
                  expanded={expandedIndex === ind}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ArrowIcon style={{fontSize: '13px'}}/>}
                    className={clsx({
                      "text-primary font-medium": true,
                      [classes.bgLightGray]: true,
                      "text-white": expandedIndex === ind,
                    })}
                  >
                    {item.title}
                  </ExpansionPanelSummary>
                  <AccordionDetails>
                    <p style={{textAlign: 'left'}}>
                      {item.description}
                    </p>
                  </AccordionDetails>
                </ExpansionPanel>
              ))}
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
export default FrequentlyAskedQuestionsView;

