import React from 'react';
import {Button} from "@material-ui/core";

const DidntFindAnswerView = () => {
  return (
    <>
      <div className="text-center mb-10">
        <h2>Didn't find an answer?</h2>
        <p>Get in touch with us for details on setup and additional custom services pricing</p>
        <a href="https://wanclouds.net/contact-us">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="large"
          >
            Contact Us
          </Button>
        </a>
      </div>
    </>
  );
}
export default DidntFindAnswerView;
