import React from 'react';
import {Container} from '@material-ui/core';
import SearchView from "./Content/Search.view";
import CloudTabsView from "./Content/CloudTabs.view";
import PopularArticles from "./Content/PopularArticles.view";
import VideoTutorialView from "./Content/VideoTutorials.view";
import FrequentlyAskedQuestionsView from "./Content/FrequentlyAskedQuestions.view";
import DidntFindAnswerView from "./Content/DidntFindAnswer.view";
import SEO from "../../Common/SEO/seo";

const LandingPage = ({data}) => {
  return [
    <>
      <SEO
      title="Doc & Support"
      description="Stay updated on the latest news, upcoming events, and current trends from the cloud industry with Wanclouds."
      keywords="blogs, multi cloud, DRaaS, disaster recovery, newsfeed, MaaS, cloud migrations, cloud computing, public cloud, multi-cloud migrations, tech news, vpc"
    />
    <div className="pt-5">
      <Container maxWidth="lg">
        <div className="landing">
          <SearchView/>
          <CloudTabsView/>
          <PopularArticles/>
          <VideoTutorialView/>
          <FrequentlyAskedQuestionsView/>
          <DidntFindAnswerView/>
        </div>
      </Container>
    </div>
    </>
  ];
}
export default LandingPage;

